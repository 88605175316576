<template>
  <SelectMultiple v-model="internalValue" :items="selectMultipleItems" label="Empresa" has-select-all />
</template>

<script>
import { groupBy, uniqueId } from 'lodash';

export default {
  name: 'EstablishmentSelect',

  components: {
    SelectMultiple: () => import('../../commons/SelectMultiple.vue'),
  },

  props: {
    companies: [],
    value: {
      type: [Boolean, String, Number, Array, Object],
      default: null,
    },
  },

  data: () => ({
    internalValue: null,
    selectMultipleItems: [],
  }),

  watch: {
    companies: {
      handler() {
        this.update();
      },

      deep: true,
      immediate: true,
    },

    value(newValue) {
      this.internalValue = newValue;
    },

    internalValue(newValue) {
      // emit an 'input' to update v-model in parent
      this.$emit('input', newValue);

      // emit an 'change' to trigger @change in parent
      this.$emit('change', newValue);
    },
  },

  created() {
    this.internalValue = this.value;
  },

  methods: {
    update() {
      this.selectMultipleItems = this.parseSelectMultipleItemsFromCompanies(this.companies);
    },

    parseSelectMultipleItemsFromCompanies(companies) {
      let establishments = this.getEstablishmentsFromCompanies(companies);
      return this.parseSelectMultipleItemsFromEstablishments(establishments);
    },

    getEstablishmentsFromCompanies(companies) {
      return companies.map((company) => company.establishment);
    },

    parseItemFromEstablishment(establishment, groupId) {
      return {
        itemTitle: establishment.details.socialName,
        itemSubtitle: establishment.details.cnpj,
        groupId: groupId,
        itemId: establishment.id,
        details: establishment.details,
      };
    },

    parseSelectMultipleItemsFromEstablishments(establishments) {
      let groups = groupBy(establishments, 'socialName');

      return Object.entries(groups).map(([key, value]) => {
        let groupId = uniqueId('_item_group');

        return {
          groupTitle: key,
          groupId: groupId,
          items: value.map((establishment) => this.parseItemFromEstablishment(establishment, groupId)),
        };
      });
    },
  },
};
</script>
