var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SelectMultiple", {
    attrs: {
      items: _vm.selectMultipleItems,
      label: "Empresa",
      "has-select-all": "",
    },
    model: {
      value: _vm.internalValue,
      callback: function ($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }